import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axiosClient from "../../axios-client.jsx";
import './register.css';
import '../../App.css';

export default function Login() {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isUserSucces, setIsUserSuccess] = useState(false);
    const [isUserError, setIsUserError] = useState("");
    const [isErrors, setIsErrors] = useState([]);

    const handleRegister = async () => {
        const data = {
            'email':email,
            'password':password
        };
        await axiosClient.post('register', data).then(({data}) => {
            if (data.success) {
                setIsUserSuccess(true);
            } else {
                setIsUserError(data.message);
                if (data.errors) {
                    setIsErrors(data.errors);
                }
            }
            return;
        }).catch(error => {
            const response = error.response;
            if (response && response.status === 422) {
            }
        });
    };

    const handleLogin = () => {
        navigate('/login');
    }


    return (
        <div className='register-div'>
            <div className='register-form-div'>
                <div className='register-title-div'><h1>Kayıt Ol</h1></div>
                {isUserSucces && (
                    <div className='register-success'>Kullanıcı oluşturuldu &#10003;</div>
                )}
                {isUserError && (
                    <div className='register-error'>{isUserError}</div>
                )}
                <div className='register-input-div'>
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                    {isErrors.email && (
                        <div className='error-input'>{isErrors.email}</div>
                    )}
                </div>
                <div className='register-input-div'>
                    <label htmlFor="">Şifre</label>
                    <input type="password" onChange={(e)=>setPassword(e.target.value)} />
                    {isErrors.password && (
                        <div className='error-input'>{isErrors.password}</div>
                    )}
                </div>
                <div className='register-button-div'><button onClick={handleRegister} style={{textDecoration:"none"}}>Kayıt Ol</button></div>
                <div className='login-div'><button onClick={handleLogin}>Giriş yap</button></div>
            </div>
        </div>
    )
}
