import React from 'react'
import { useDispatch } from 'react-redux'
import { setModalType } from '../../features/modalSlice';

export default function NewRecordModal() {
    const dispatch = useDispatch();

    return (
        <div className='p-6 py-10'>
            <h2 className='text-center mb-10 border py-3'>Yeni Kayıt Seçimi</h2>
            <div className='flex flex-row justify-evenly'>
                <div className='p-5 px-10 bg-gray-100 shadow hover:bg-gray-50 cursor-pointer' onClick={() => dispatch(setModalType('add_category'))} >Kategori Ekle</div>
                <div className='p-5 px-10 bg-gray-100 shadow hover:bg-gray-50 cursor-pointer' onClick={() => dispatch(setModalType('add_note'))} >Not Ekle</div>
            </div>
        </div>
    )
}
