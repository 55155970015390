import axios from "axios";

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/`
})

axiosClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    config.headers.Authorization = `Bearer ${token}`
    return config;
})

axiosClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    try {
        const { response } = error;
        if (response.status === 401) {
            localStorage.removeItem('access_token');
        }
    } catch(err) {
        //console.log('err', err)
    }

    throw error;
})

export default axiosClient;