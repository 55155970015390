import React from 'react'
import { useSelector } from 'react-redux';


export default function NoteEditModal() {
    const modalData = useSelector((state) => state.modalSlice);

    
    return (
        <div>
            <div className='p-6'>
                <div className='flex justify-center align-middle'>
                    <h2 className="self-center flex-grow text-lg font-medium text-gray-900">Not Detayı</h2>
                </div>
                <form className="">
                    <div className="mt-3">
                        <div>Kategori</div>
                        <div><b>Test</b></div>
                    </div>

                    <div className="mt-3">
                        <div>Başlık</div>
                        <div className="mt-1 block w-full py-1 px-2 border rounded">
                            {modalData.data.title}
                        </div>
                    </div>

                    <div className="mt-3">
                        <div>Not</div>
                        <div className="mt-1 block w-full py-1 px-2 border h-48 rounded overflow-y-auto">{modalData.data.note}</div>
                    </div>
                </form>
            </div>
        </div>
    )
}
