import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";

export default function LoadingSpinner() {
    return (
        <ClipLoader
            color={"#000"}
            loading={true}
            size={25}
        />
    )
}
