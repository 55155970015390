import React, { useState } from 'react'
import InputLabel from '../../../components/InputLabel.jsx';
import TextInput from '../../../components/TextInput.jsx';
import InputError from '../../../components/InputError';
import DangerButton from '../../../components/DangerButton.jsx';
import PrimaryButton from '../../../components/PrimaryButton.jsx';
import TextArea from '../../../components/TextArea.jsx';
import { TbArrowBackUp } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import { setNotesList } from '../../../features/notesListSlice.jsx';
import { useParams } from 'react-router-dom';
import axiosClient from '../../../axios-client.jsx';
import { toast } from 'react-toastify';
import { setModalType, closeModal } from '../../../features/modalSlice.jsx';


export default function NoteEditModal() {
    const { categoryId } = useParams();
    const dispatch = useDispatch();
    const notesList = useSelector((state) => state.notesListSlice);
    const modalData = useSelector((state) => state.modalSlice);
    const [data, setData] = useState(modalData.type==='edit_note' ? modalData.data : null);
    const [errors, setErrors] = useState({});

    
    const handleNewRecord = async () => {
        let postData = {
            category_id: categoryId ? categoryId : null,
            title: data?.title,
            note: data?.note
        };
        try {
            let response = await axiosClient.post('note/store', postData);
            if (response.data.success) {
                let responseData = response.data.result.data;
                let newData = {
                    id:responseData.id,
                    category_id:responseData.category_id,
                    title:responseData.title,
                    note:responseData.note
                };
                let tmpArr = { ...notesList };
                let notes = tmpArr.notes;
                let newNotes = [...notes, newData];
                tmpArr.notes = newNotes;
                dispatch(setNotesList(tmpArr));
                dispatch(closeModal());
                toast.success(response.data.message);
                setData({});
            } else {
                setErrors(response.data.errors)
            }
        } catch (error) {
        }
    }

    const handleUpdateRecord = async () => {
        let postData = {
            id: data.id,
            title: data.title,
            note: data.note
        };
        try {
            let response = await axiosClient.patch('note/update', postData);
            if (response.data.success) {
                let tmpArr = { ...notesList };
                let notes = tmpArr.notes;
                let newNotes = notes.map((item) => {
                    if (item.id === postData.id) {
                        return {...item, title:postData.title, note:postData.note};
                    } else {
                        return item;
                    }
                })
                tmpArr.notes = newNotes;
                dispatch(setNotesList(tmpArr));
                dispatch(closeModal());
                toast.success(response.data.message);
                setData({});
            } else {
                setErrors(response.data.errors)
            }
        } catch (error) {
        }
    }

    const handleTitleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            return;
        }
    }
    const handleSubmitOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleNewRecord(e);
            return;
        }
    }

    return (
        <div>
            <div className='p-6'>
                <div className='flex justify-center align-middle'>
                    <h2 className="self-center flex-grow text-lg font-medium text-gray-900">Kategori Ekle</h2>
                    <div><button className='h-full p-1 bg-gray-50 rounded' onClick={() => dispatch(setModalType(null))} ><TbArrowBackUp /></button></div>
                </div>
                <form className="">
                    <div className="mt-3">
                        <InputLabel htmlFor="parent_id" value="Kategori" />
                        <div><b>Test</b></div>
                    </div>

                    <div className="mt-3">
                        <InputLabel htmlFor="title" value="Başlık" />

                        <TextInput
                            id="title"
                            type="text"
                            name="title"
                            value={data?.title ? data.title : ''}
                            onChange={(e) => setData({ ...data, title: e.target.value })}
                            className="mt-1 block w-full py-1 px-2 border"
                            isFocused
                            placeholder="Başlık"
                            onKeyDown={handleTitleOnKeyDown}
                        />

                        <InputError message={errors.title} className="mt-1" />
                    </div>

                    <div className="mt-3">
                        <InputLabel htmlFor="note" value="Açıklama" />

                        <TextArea
                            id="note"
                            name="note"
                            value={data?.note ? data.note : ''}
                            onChange={(e) => setData({ ...data, note: e.target.value })}
                            className="mt-1 block w-full py-1 px-2 border h-48"
                            placeholder="Açıklama"
                            onKeyDown={handleSubmitOnKeyDown}
                        />

                        <InputError message={errors.note} className="mt-1" />
                    </div>
                </form>
                <div className="mt-6 flex justify-end">
                    <DangerButton onClick={() => dispatch(closeModal())} >Vazgeç</DangerButton>
                    <PrimaryButton className="ms-3" onClick={data?.id?handleUpdateRecord:handleNewRecord} >Kaydet</PrimaryButton>
                </div>
            </div>
        </div>
    )
}
