import React, { useState } from 'react'
import InputLabel from '../../../components/InputLabel';
import TextInput from '../../../components/TextInput';
import InputError from '../../../components/InputError';
import PrimaryButton from '../../../components/PrimaryButton.jsx';
import DangerButton from '../../../components/DangerButton.jsx';
import axiosClient from '../../../axios-client.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setNotesList } from '../../../features/notesListSlice.jsx';
import { toast } from 'react-toastify';
import { closeModal } from '../../../features/modalSlice.jsx';

export default function CategoryEdit() {
    const dispatch = useDispatch();
    const notesList = useSelector((state) => state.notesListSlice);
    const modalData = useSelector((state) => state.modalSlice);

    const [data, setData] = useState(modalData.data);
    const [errors, setErrors] = useState({});

    const handleSaveButton = async () => {
        try {
            let postData = {
                id: data.id,
                name: data.name,
            };
            let response = await axiosClient.patch('category/update', postData);
            if (response.data.success) {
                let tmpArr = { ...notesList };
                let subCategories = tmpArr.sub_categories;
                let newSubCategories = subCategories.map((item) => {
                    if (item.id === postData.id) {
                        return {...item, name:postData.name};
                    } else {
                        return item;
                    }
                })
                tmpArr.sub_categories = newSubCategories;
                dispatch(setNotesList(tmpArr));
                dispatch(closeModal());
                toast.success(response.data.message);
            } else {
                setErrors(response.data.errors)
            }
        } catch (error) {
            
        }
    }

    const handleSubmitOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSaveButton(e);
            return;
        }
    }


    return (
        <div className='p-6'>
            <div className='flex justify-center align-middle'>
                <h2 className="self-center flex-grow text-lg font-medium text-gray-900">Kategori Düzenle</h2>
            </div>
            <div className="mt-3">
                <InputLabel htmlFor="name" value="Kategori Adı" />

                <TextInput
                    id="name"
                    type="text"
                    name="name"
                    value={data?.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    className="mt-1 block w-full py-1 px-2 border"
                    placeholder="Başlık"
                    onKeyDown={handleSubmitOnKeyDown}
                />

                <InputError message={errors.name} className="mt-1" />
            </div>

            <div className="mt-6 flex justify-end">
                <DangerButton onClick={() => dispatch(closeModal())} >Vazgeç</DangerButton>
                <PrimaryButton className="ms-3" onClick={handleSaveButton} >Kaydet</PrimaryButton>
            </div>
        </div>
    )
}
