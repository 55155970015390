import { configureStore } from '@reduxjs/toolkit';
import tokenSlice from './features/auth/tokenSlice';
import notesListSlice from './features/notesListSlice';
import modalSlice from './features/modalSlice';
import stdErrorModalSlice from './features/std_modals/errorModalSlice';


export const store = configureStore({
  reducer: {
    tokenSlice,
    notesListSlice,
    modalSlice,
    stdErrorModalSlice
  },
})