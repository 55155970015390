import { useEffect } from 'react';

export default function TextArea({ className = '', isFocused = false, ...props }) {
    return (
        <textarea
            {...props}
            className={
                'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm h-72' +
                className
            }
        />
    );
};
