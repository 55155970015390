import './App.css';
import { Outlet, Navigate } from 'react-router-dom';
import Header from './pages/Header';

function App() {
  
  const token = localStorage.getItem('accessToken');
  if (!token) {
    return <Navigate to="/login" />
  }

  return (
    <div className="App">
      <Header />
      <Outlet />
    </div>
  );
}

export default App;
