import React from 'react'
import '../../css/categories_list.css';
import CategoryListItem from './category/CategoryListItem';
import NoteListItem from './note/NoteListItem';
import { useNavigate, useParams } from 'react-router-dom';
import { TbArrowBackUp } from "react-icons/tb";

export default function NotesList({ notesList }) {
    const { categoryId } = useParams();
    const navigate = useNavigate();

    let parent_id = notesList?.parent_id ? notesList.parent_id : '';

    return (
        <div>
            <div className='flex justify-center mt-5 mb-20'>
                <div className='w-full md:w-2/3'>
                    <div className='flex justify-between py-2 mb-3'>
                        <h2 className='underline font-semibold text-lg'>{categoryId ? 'ALT KATEGORİLER' : 'KATEGORİLER'}</h2>
                        {notesList?.id && (
                            <div><button className='h-full p-1 bg-gray-100 rounded' onClick={() => navigate('/notes/'+parent_id)}><TbArrowBackUp /></button></div>
                        )}
                    </div>
                    {notesList?.sub_categories?.length > 0 ? (
                        <ul>
                            {notesList.sub_categories.map((item, index) => (
                                <CategoryListItem key={item.id} item={item} />
                            ))}
                        </ul>
                    )
                    : (
                        <div className='bg-blue-50 p-2 text-center font-semibold rounded-sm'>Kayıt bulunmuyor</div>
                    )}
                </div>
            </div>
            
            {categoryId && (
                <div className='flex justify-center mt-8'>
                    <div className='w-full md:w-2/3'>
                        <h2 className='underline py-2 mb-3 font-semibold text-lg'>NOTLAR</h2>
                        {notesList?.notes?.length > 0 ? (
                            <ul>
                                {notesList.notes.map((item, index) => (
                                    <NoteListItem key={item.id} item={item} />
                                ))}
                            </ul>
                        )
                        : (
                            <div className='bg-blue-50 p-2 text-center font-semibold rounded-sm'>Kayıt bulunmuyor</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
