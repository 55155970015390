import React, { useState } from 'react';
import InputLabel from '../../../components/InputLabel.jsx';
import TextInput from '../../../components/TextInput.jsx';
import DangerButton from '../../../components/DangerButton.jsx';
import PrimaryButton from '../../../components/PrimaryButton.jsx';
import InputError from '../../../components/InputError.jsx';
import { TbArrowBackUp } from "react-icons/tb";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosClient from '../../../axios-client.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setNotesList } from '../../../features/notesListSlice.jsx';
import { setModalType, closeModal } from '../../../features/modalSlice.jsx';


export default function AddCategoryModal() {
    const { categoryId } = useParams();
    const dispatch = useDispatch();
    const notesList = useSelector((state) => state.notesListSlice);

    const [data, setData] = useState();
    const [errors, setErrors] = useState({});

    const handleNewRecord = async () => {
        try {
            let postData = {
                parent_id: categoryId ? categoryId : null,
                name: data?.name,
            };
            let response = await axiosClient.post('category/store', postData);
            if (response.data.success) {
                let responseData = response.data.result;
                let newData = {
                    id: responseData.id,
                    parent_id: responseData.parent_id,
                    name: responseData.name,
                    sub_categories_count: 0,
                    notes_count: 0
                };
                let tmpArr = { ...notesList };
                let subCategories = tmpArr.sub_categories;
                let newSubCategories = [...subCategories, newData];
                tmpArr.sub_categories = newSubCategories;
                dispatch(setNotesList(tmpArr));
                dispatch(closeModal());
                toast.success(response.data.message);
                setData({});
            } else {
                setErrors(response.data.errors)
            }
        } catch (error) {
            
        }
    }

    const handleSubmitOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleNewRecord(e);
            return;
        }
    }


    return (
        <div className='p-6'>
            <div className='flex justify-center align-middle'>
                <h2 className="self-center flex-grow text-lg font-medium text-gray-900">Kategori Ekle</h2>
                {categoryId && (
                    <div><button className='h-full p-1 bg-gray-50 rounded' onClick={() => dispatch(setModalType(null))} ><TbArrowBackUp /></button></div>
                )}
            </div>
            <form>
                <div className='mt-3'>
                    <InputLabel htmlFor="parent_id" value="Ana Kategori" />
                    <div><b>Test</b></div>
                </div>
                <div className='mt-3'>
                    <InputLabel htmlFor="title" value="Başlık" />

                    <TextInput
                        id="name"
                        type="text"
                        name="name"
                        value={data?.name ? data.name : ''}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        className="mt-1 block w-full py-1 px-2 border"
                        isFocused
                        placeholder="Başlık"
                        onKeyDown={handleSubmitOnKeyDown}
                    />
                    
                    <InputError message={errors.name} className="mt-1" />
                </div>
            </form>
            <div className="mt-6 flex justify-end">
                <DangerButton onClick={() => dispatch(closeModal())} >Vazgeç</DangerButton>
                <PrimaryButton className="ms-3" onClick={handleNewRecord} >Kaydet</PrimaryButton>
            </div>
        </div>
    )
}
