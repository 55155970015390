import React, { useEffect, useState } from 'react'
import axiosClient from "../../axios-client.jsx";
import NotesList from "./NotesList.jsx";
import Modal from '../../components/Modal.jsx';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setNotesList } from '../../features/notesListSlice.jsx';
import LoadingSpinner from '../../components/LoadingSpinner.jsx';
import AddCategoryModal from './category/AddCategoryModal.jsx';
import NoteEditModal from './note/NoteEditModal.jsx';
import NoteDetail from './note/NoteDetail.jsx';
import CategoryEdit from './category/CategoryEdit.jsx';
import NewRecordModal from './NewRecordModal.jsx';
import StdErrorModal from '../../components/modals/StdErrorModal.jsx';
import { setModalType, openModal, closeModal } from '../../features/modalSlice.jsx';
import { stdOpenErrorModal } from '../../features/std_modals/errorModalSlice.jsx';


export default function Notes() {

    const dispatch = useDispatch();
    const notesList = useSelector((state) => state.notesListSlice);
    const modalData = useSelector((state) => state.modalSlice);
    const stdErrorModalData = useSelector((state) => state.stdErrorModalSlice);
    const { categoryId } = useParams();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const fetchApi = async () => {
            setLoading(true);
            await axiosClient.get('notes/' + (categoryId ? categoryId : ''), {
            }).then(({ data }) => {
                if (data.success) {
                    dispatch(setNotesList(data.result));
                }
            }).catch((error) => {
                console.log('### NOT_FOUND ###')
            });
            setLoading(false);
        }
        fetchApi();
    }, [categoryId])


    function openModalFunc() {
        if (categoryId) {
            dispatch(openModal(true));
        } else {
            dispatch(setModalType('add_category'));
        }
    }

    return (
        <>
            <div>
                <div className='mt-14 text-black w-auto'>
                    {loading ? <div className='w-full h-screen fcc'><LoadingSpinner /></div> : (
                        <>
                            <div className='flex w-full justify-between px-5 py-2 sticky top-14 left-0 align-middle bg-white'>
                                <div className='text-xs underline'>
                                    <div className='flex'>
                                        {/* {categoryPath.map((item) => (
                                            <div className='flex'>
                                                <span>
                                                    {item.name}
                                                </span> &nbsp;{">"}&nbsp;
                                            </div>
                                        ))} */}
                                    </div>
                                </div>
                                <div><button className='underline text-gray-700' onClick={() => openModalFunc()}>Yeni Ekle</button></div>
                            </div>

                            <NotesList notesList={notesList} />
                        </>
                    )}
                </div>
            </div>

            <Modal show={modalData.open} onClose={() => dispatch(closeModal())} maxWidth='2xl'>
                {modalData.type === 'add_category' ? (
                    <AddCategoryModal />
                ) : modalData.type === 'add_note' || modalData.type === 'edit_note' ? (
                    <NoteEditModal />
                ) : modalData.type === 'note_detail' ? (
                    <NoteDetail />
                ) : modalData.type === 'edit_category' ? (
                    <CategoryEdit />
                ) : modalData.type === null && (
                    <NewRecordModal />
                )}
            </Modal>

            <Modal show={stdErrorModalData.open} onClose={() => dispatch(stdOpenErrorModal(false))} maxWidth='md'>
                <StdErrorModal title={stdErrorModalData.title} message={stdErrorModalData.message} />
            </Modal>
        </>
    )
}
