import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axiosClient from "../../axios-client.jsx";
import './login.css';
import '../../App.css';
import { useDispatch } from 'react-redux';
import { setToken } from '../../features/auth/tokenSlice.jsx';

export default function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isUserError, setIsUserError] = useState("");
    const [isErrors, setIsErrors] = useState([]);

    const handleLogin = async () => {
        const data = {
            'email':email,
            'password':password
        };
        await axiosClient.post('login', data).then(({data}) => {
            if (data.success) {
                dispatch(setToken(data.result.token));
                navigate('/')
            } else {
                setIsUserError(data.message);
                if (data.errors) {
                    setIsErrors(data.errors);
                }
            }
            return;
        }).catch(error => {
            const response = error.response;
            if (response && response.status === 422) {
            }
        });
    };

    const handleRegister = async() => {
        navigate('/register');
    }


    return (
        <div className='login-div'>
            <div className='login-title-div'><h1 className=''>NVINOTE</h1></div>
            {isUserError && (
                <div className='register-error'>{isUserError}</div>
            )}
            <div className='login-form-div'>
                <div className='login-input-div'>
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                    {isErrors.email && (
                        <div className='error-input'>{isErrors.email}</div>
                    )}
                </div>
                <div className='login-input-div'>
                    <label htmlFor="">Şifre</label>
                    <input type="password" onChange={(e)=>setPassword(e.target.value)} />
                    {isErrors.password && (
                        <div className='error-input'>{isErrors.password}</div>
                    )}
                </div>
                <div className='login-button-div'><button onClick={handleLogin} style={{textDecoration:"none"}}>Giriş Yap</button></div>
                <div className='register-div'><button onClick={handleRegister}>Hesabın yoksa kayıt ol</button></div>
            </div>
        </div>
    )
}
