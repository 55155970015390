import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token:null
};

export const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setToken: (state, actions) => {
            localStorage.setItem('accessToken', actions.payload);
        },
        logout: (state, actions) => {
            state.token = null;
            localStorage.clear();
        }
    },
})

export const { setToken, logout } = tokenSlice.actions;
export default tokenSlice.reducer;