import '../css/header.css';
import { Link, useNavigate } from "react-router-dom";
import { SlNotebook } from "react-icons/sl";
import { FaUser } from "react-icons/fa";
import { useState } from "react";
import { logout } from '../features/auth/tokenSlice';
import { useDispatch } from 'react-redux';


export default function Header() {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const profileMenu = () => {
        setIsOpen(!isOpen);
    };

    const logoutFunc = () => {
        dispatch(logout());
        navigate('/login');
        return;
    }

    return (
        <div className="w-full h-14 bg-white fixed top-0 left-0 z-50 shadow-sm shadow-blue-200">
            <div className="h-full flex justify-between">
                {/* <div className="header-sidebar-toggle-button-div"><button><IoIosList className="text-2xl hover:text-gray-400" /></button></div> */}
                <div className="header-main-div">
                    <ul className="m-0 p-0">
                        <li>
                            <Link to="notes">
                                <SlNotebook className="text-xl hover:text-gray-400" />
                                <span>Notlar</span>
                            </Link>
                        </li>
                    </ul>
                </div>


                <div className="header-right-icon-div">
                    <ul className="m-0 p-0">
                        <li className="relative group inline-block">
                            <div><FaUser onClick={profileMenu} /></div>
                            <div className={`w-32 absolute top-6 right-0 transition-opacity duration-200 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
                                <ul className="bg-white shadow shadow-gray-400 text-sm" style={{ display: "flex", flexDirection: "column" }}>
                                    {/* <li className="block border-b cursor-pointer hover:bg-gray-100">Profil</li> */}
                                    <li className="block cursor-pointer hover:bg-gray-100" onClick={logoutFunc}>Çıkış</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
