import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open:false,
    type:null,
    data:null
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, actions) => {
            state.open = actions.payload;
        },
        closeModal: (state, actions) => {
            state.open = false;
            state.data = null;
            state.type = null;
        },
        setModalType: (state, actions) => {
            state.type = actions.payload;
            state.open = true;
        },
        setModalData: (state, actions) => {
            state.data = actions.payload.data;
            state.type = actions.payload.type;
            state.open = true;
        }
    },
})

export const { openModal, closeModal, setModalType, setModalData } = modalSlice.actions;
export default modalSlice.reducer;