export default function InputError({ message, className = '', ...props }) {
    return message ? (
            Array.isArray ? (message.map((item, index) => (
                <p key={index} {...props} className={'text-sm text-red-600 ' + className}>
                    {item}
                </p>
            ))) : (
                <p {...props} className={'text-sm text-red-600 ' + className}>
                    {Array.isArray(message) ? message[0] : message}
                </p>
            )
        ) : null;
}
