import { createSlice } from '@reduxjs/toolkit'

const initialState = {};

export const notesListSlice = createSlice({
    name: 'notesList',
    initialState,
    reducers: {
        setNotesList: (state, actions) => state = actions.payload
    },
})

export const { setNotesList } = notesListSlice.actions;
export default notesListSlice.reducer