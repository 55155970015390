import React from 'react'
import '../../css/modals/stdErrorModal.css';
import { useDispatch } from 'react-redux';
import { stdOpenErrorModal } from '../../features/std_modals/errorModalSlice';

export default function StdErrorModal({ title=null, message=null }) {
  const dispatch = useDispatch();
  return (
    <div className='py-8 stderrormodal-g'>
        <div className='absolute top-3 right-4'><button className='px-2 py-1 bg-gray-200' onClick={()=>dispatch(stdOpenErrorModal(false))}>&#10005;</button></div>
        <div className='text-5xl font-bold text-red-500'>&#10005;</div>
        <div><h1 className='text-center p-4 font-bold text-xl'>{title?title:"İşlem Başarısız"}</h1></div>
        <span className='text-lg px-4'>
            {message?message:'Hata..!'}
        </span>
    </div>
  )
}
