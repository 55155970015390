import { createBrowserRouter } from "react-router-dom";
import App from "../App.js";
import Login from "../pages/auth/Login.jsx";
import Register from "../pages/auth/Register.jsx";
import Notes from "../pages/notes/Notes.jsx";
import _404 from "../pages/_404.jsx";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <_404 />,
        children: [
            {
                path: "/notes",
                element: <Notes />,
                children: [
                    {
                        path: "/notes/:categoryId",
                        element: <Notes />
                    }
                ]
            }
        ]
    },
    {
        path: "/login",
        element: <Login />,
        errorElement: <div>Error Element !!</div>,
    },
    {
        path: "/register",
        element: <Register />,
        errorElement: <div>Error Element !!</div>,
    },
    {
        path: "*",
        element: <_404 />,
        errorElement: <div>404 - Page Not Found !!</div>,
    },
]);