import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open:false,
    title:null,
    message:null
};

export const errorModalSlice = createSlice({
    name: 'stdErrorModal',
    initialState,
    reducers: {
        stdOpenErrorModal: (state, actions) => {
            state.open = actions.payload;
        },
        setStdErrorModalMessage:(state, actions) => {
            state.open = true;
            state.title = actions.payload?.title?actions.payload.title:null;
            state.message = actions.payload.message;
        }
    },
})

export const { stdOpenErrorModal, setStdErrorModalMessage } = errorModalSlice.actions;
export default errorModalSlice.reducer;