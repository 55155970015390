import { FaFolderOpen } from "react-icons/fa";
import { RiFileList2Line } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setModalData } from "../../../features/modalSlice";
import axiosClient from "../../../axios-client";
import { setNotesList } from "../../../features/notesListSlice";
import { setStdErrorModalMessage } from "../../../features/std_modals/errorModalSlice";

export default function CategoryListItem({ item }) {

    const notesList = useSelector((state) => state.notesListSlice);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCategoryDetail = async (item) => {
        navigate('/notes/'+item.id);
    }

    const handleEditCategory = (e, item) => {
        e.stopPropagation();
        dispatch(setModalData({'type':'edit_category', data:item}));
    }

    const handleDeleteCategory = async (e, id) => {
        e.stopPropagation();
        await axiosClient.delete('category/delete/'+id, {
        }).then(({ data }) => {
            if (data.success) {
                let tmpArr = {...notesList};
                tmpArr.sub_categories = tmpArr.sub_categories.filter((item) => item.id !== id);
                dispatch(setNotesList(tmpArr))
            } else {
                dispatch(setStdErrorModalMessage({message:data.message}));
            }
        }).catch((error) => {
            console.log('### NOT_FOUND ###')
        });
    }

    return (
        <li className='flex row justify-between align-middle h-8 cursor-pointer mb-4 shadow-sm shadow-gray-300 hover:shadow-gray-400 rounded-sm' onClick={() => handleCategoryDetail(item)}>
            <div className='icon-name-flex ms-4 flex row align-middle'>
                <FaFolderOpen className='mr-2' />
                <div className='text-md'>{item.name}</div>
            </div>
            <div className='h-auto flex align-middle'>
                <ul className='categorieslist-righticon-ul'>
                    <li className='icon-name-flex flex row'>
                        <FaFolderOpen className="mr-2" /> <span>{item.sub_categories_count}</span>
                    </li>
                    <li className='icon-name-flex flex row'>
                        <RiFileList2Line className="mr-2" /> <span>{item.notes_count}</span>
                    </li>
                    <li className='icon-name-flex h-full w-6 hover:bg-gray-200 cursor-pointer' onClick={(e) => handleEditCategory(e, item)}>
                        <MdEdit />
                    </li>
                    <li className='icon-name-flex h-full w-6 hover:bg-gray-200 cursor-pointer' onClick={(e) => handleDeleteCategory(e, item.id)}>
                        <MdDelete />
                    </li>
                </ul>
            </div>
        </li>
    )
}